import { React, useState } from 'react';

import {
    Outlet,
    useLoaderData,
    useLocation,
    Form,
    useSubmit,
    ScrollRestoration
} from "react-router-dom";

import { useEffect } from "react";

import { Link } from "react-router-dom";

import { useIndexAndCategoriesData } from '../DataFetcher'
import { Translate, translate, useLocale } from '../Locale'
import { useSearchEngine } from '../SearchEngine'
import { Logo } from '../components/Logo';

import Collapse from 'bootstrap/js/dist/collapse';

export async function loader({ request }) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q");

    // Must load the version.txt to see what the
    // current version is...
    // then load corresponding JSON file
    /*
    const index = useQuery({
        queryKey: ["index"],
        queryFn: async () => {
            const { data } = await axios.get();
            return data;
        },
    });
    */

    //const index = useIndex();
    //const { status, data, error, isFetching } = useIndex();

    // TODO: load search results instead of contacts
    //const contacts = await getContacts(q);
    //var max = Math.floor(Math.random() * staticResults.length);
    //const results = staticResults.slice(0, Math.max(1, max));

    return { q };
}

export default function Root() {
    const location = useLocation();

    // If we're processing a referral URL, we don't want to load
    // the header or pre-load the search engine.
    //
    // This is a hack because ideally we should have a subdomain
    // such as 'referrals.tirespy.ca' and then load a completely
    // separate and independent web-app, but that seems overkill
    // and unecessary right now.
    if (location.pathname.startsWith("/r/")) {
        return <ReferralRoot />
    } else {
        return <WebsiteRoot />
    }
}

function ReferralRoot() {
    return (
        <>
            <div id="content" className="container outlet">
                <Outlet />
            </div>
            <div id="footer" className="container">
                <Footer hideLinks={true} hideLangs={true}/>
            </div>
        </>
    );
}

function WebsiteRoot() {
    // Pre-load the search engine
    useSearchEngine();
    
    return (
        <>
            <div id="header" className="sticky-top border-bottom">
                <Header />
            </div>

            <div id="content" className="container outlet">
                <Outlet />
            </div>

            <div id="footer" className="container">
                <Footer hideLinks={false} hideLangs={true}/>
            </div>

            <ScrollRestoration
                getKey={(location, matches) => {
                    /*
                    const paths = ["/home", "/notifications"];
                    return paths.includes(location.pathname)
                      ? // home and notifications restore by pathname
                        location.pathname
                      : // everything else by location like the browser
                        location.key;
                        */

                    // default behavior
                    return location.key;
                }}
            />
        </>
    );
}

function Header() {
    //const [isLoading, isError, error] = useIndexAndCategoriesData();
    //const navigation = useNavigation();
    const locale = useLocale();
    const submit = useSubmit();
    const { q } = useLoaderData();
    const [menuToggle, setMenuToggle] = useState(false);

    useEffect(() => {
        document.getElementById("q").value = q;
    }, [q]);
    
    useEffect(() => {
        var btnEl = document.getElementById('navbarBtn');
        if (btnEl && window.getComputedStyle(btnEl).display === "none") {
            // Don't toggle if button isn't visible
            return;
        }

        var navEl = document.getElementById('navbarNav');
        if (navEl) {
            var bsCollapse = new Collapse(navEl, {toggle: false});
            menuToggle ? bsCollapse.show() : bsCollapse.hide();
        }
    }, [menuToggle]);
    
    function toggleMenu() {
        setMenuToggle(!menuToggle);
    }

    //const searching = navigation.location && new URLSearchParams(navigation.location.search).has("q");
    //const searching = true;
    //const [isLoading, isError] = useIndexAndCategoriesData();
    //const navigation = useNavigation();
    //const navigationLoading = navigation.state === "loading";
    //const navigationLoading = true;
    //const navigationLoading = isLoading;

    var searchIcon;
    if (false /*isLoading*/) {
        searchIcon = (
            <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                {/*<span className="sr-only">Loading...</span>*/}
            </>
        );
    } else {
        searchIcon = (
            <>
                <i className="bi bi-search" />
                {/*<span className="sr-only">Search</span>*/}
            </>
        );
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Logo />

                <Form className="col me-2" role="search" action="search">
                    <div className="input-group">
                        <input
                            id="q"
                            name="q"
                            type="search"
                            className="form-control navbar-element"
                            placeholder={translate("navbar-search", locale)}
                            aria-label={translate("navbar-search", locale)}
                            aria-describedby="button-search"
                            defaultValue={q}
                            autoFocus
                            onChange={(event) => {
                                const isFirstSearch = q == null;
                                submit(event.currentTarget.form, {
                                    replace: !isFirstSearch,
                                });
                            }}
                            onKeyDown={(event) => {
                                if (event.key == 'Enter') {
                                    event.target.blur();
                                }
                            }}
                        />

                        <button type="submit" className="btn navbar-element navbar-button">
                            {searchIcon}
                        </button>
                    </div>
                </Form>
                
                {/*
                <button className="navbar-toggler navbar-element navbar-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                */}
                <button id="navbarBtn" className="navbar-toggler navbar-element navbar-button" type="button" onClick={toggleMenu} aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div id="navbarNav" className="collapse navbar-collapse flex-grow-1">
                    <ul className="navbar-nav ms-auto">

                        {/*
                        <li className="nav-item">
                            <Link to="/pop" className="nav-link" aria-current="page" onClick={toggleMenu}>
                                <Translate string="navbar-popular-products" />
                            </Link>
                        </li>
                        */}
                        
                        <li className="nav-item">
                            <Link to="/top" className="nav-link" aria-current="page" onClick={toggleMenu}>
                                <Translate string="navbar-top-price-drops" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

function Footer(props) {
    const hideLinks = props.hideLinks;
    const hideLangs = props.hideLangs;
    const hideNotices = props.hideNotices;

    var linksJsx;
    if (props.hideLinks) {
        linksJsx = null;
    } else {
        linksJsx = (
            <ul className="nav py-2 border-top"> {/* className="... justify-content-center ..." */}
                <li className="nav-item">
                    <Link to="/" className="nav-link px-2">
                        <Translate string="footer-home" />
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/support-us" className="nav-link px-2">
                        <Translate string="footer-support-us" />
                    </Link>
                </li>

                {/*
                <li className="nav-item">
                    <Link to="/blog" className="nav-link px-2">
                        <Translate string="footer-blog" />
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/features" className="nav-link px-2">
                        <Translate string="footer-features" />
                    </Link>
                </li>
                */}
                 
                <li className="nav-item">
                    <Link to="/tos" className="nav-link px-2">
                        <Translate string="footer-tos" />
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/privacy" className="nav-link px-2">
                        <Translate string="footer-privacy" />
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/about" className="nav-link px-2">
                        <Translate string="footer-about" />
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/help" className="nav-link px-2">
                        <Translate string="footer-help" />
                    </Link>
                </li>
            </ul>
        );
    }

    var langsJsx;
    if (hideLangs) {
        langsJsx = null;
    } else {
        langsJsx = (
            <ul className="nav py-2 border-top">
                <li className="nav-item">
                    <Link className="nav-link px-2" onClick={() => {}}>
                        English
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link px-2" onClick={() => {}}>
                        Français
                    </Link>
                </li>
            </ul>
        );
    }

    var noticesJsx;
    if (hideNotices) {
        noticesJsx = null;
    } else {
        noticesJsx = (
            <div className="py-2 border-top">
                <p>
                    <small>        
                        <Translate string="product-price-tooltip" />
                    </small>
                </p>
                <p>
                    © 2025&nbsp;
                    <a href="http://newfoundsoft.com" title="Newfoundsoft, Inc." target="_blank" rel="noreferrer">
                        Newfoundsoft, Inc.
                    </a>
                </p>
            </div>
        );
    }

    return (
        <footer className="my-3"  style={{"fontSize": "smaller"}}>
            { linksJsx }
            { langsJsx }
            { noticesJsx }
        </footer>
    );
}

function FooterStyle1() {
    return (
        <footer className="">
            <div className="row">
                <div className="col-6 col-md-2 mb-3">
                    <h5>Section</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3">
                    <h5>Section</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                    </ul>
                </div>

                <div className="col-6 col-md-2 mb-3">
                    <h5>Section</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Home</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Features</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">About</a></li>
                    </ul>
                </div>

                <div className="col-md-5 offset-md-1 mb-3">
                    <form>
                        <h5>Subscribe to our newsletter</h5>
                        <p>Monthly digest of what&apos;s new and exciting from us.</p>
                        <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                            <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                            <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                            <button className="btn btn-primary" type="button">Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>© 2025 Newfoundsoft, Inc. All rights reserved.</p>
                <ul className="list-unstyled d-flex">
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#twitter"></use></svg></a></li>
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#instagram"></use></svg></a></li>
                    <li className="ms-3"><a className="link-dark" href="#"><svg className="bi" width="24" height="24"><use href="#facebook"></use></svg></a></li>
                </ul>
            </div>
        </footer>
    );
}
